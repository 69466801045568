import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'

import Icon from 'components/Icon'
import Link from 'components/Link'

import { showButton } from './animations'

import './ButtonCircle.scss'

const bgClasses = {
  white: 'bg--transp bg--border-white-op-6',
  black: 'bg--transp bg--border-gray',
  purple: 'bg--purple--alto-light',
}

/**
 * Component of circle Button
 * @return {Component} Component — ButtonCircle
 */
class ButtonCircle extends Component {
  constructor(props) {
    super(props)

    this.timerDelay = null
    this.btnAppeareTween = null
    this.wrapper = React.createRef()
    this.bg = React.createRef()
    this.text = React.createRef()

    this.state = {
      isAnimating: false,
      animationEnd: false,
    }
  }

  componentDidMount() {
    const { animation, animationDelay } = this.props

    if (animation) {
      this.timerDelay = setTimeout(() => {
        this.setState({
          isAnimating: true,
        })
      }, animationDelay)
    }
  }

  componentDidUpdate(pProps, pState) {
    const { isAnimating } = this.state

    if (isAnimating && isAnimating !== pState.isAnimating) {
      const wrapper = this.wrapper.current
      const bg = this.bg.current
      const text = this.text.current

      this.btnAppeareTween = showButton(wrapper, bg, text)
      this.btnAppeareTween = this.btnAppeareTween.call(() => {
        this.setState(
          {
            animationEnd: true,
          },
          () => {
            bg.style = {}
            text.style = {}
          },
        )
      })
    }
  }

  componentWillUnmount() {
    if (this.timerDelay) {
      clearTimeout(this.timerDelay)
    }
    if (this.btnAppeareTween) {
      this.btnAppeareTween.kill()
    }
  }

  renderButtonInner = () => {
    const {
      children,
      bgClassName,
      blackoutClassName,
      textClassName,
      icon,
      iconColor,
      iconStrokeWidth,
    } = this.props
    let content = children
    let bgColor

    if (icon) {
      content = (
        <div className="button-circle__icon">
          <Icon id={icon} color={iconColor} strokeWidth={iconStrokeWidth} />
        </div>
      )
    }

    if (bgClassName in bgClasses) {
      bgColor = bgClasses[bgClassName]
    } else {
      bgColor = bgClassName
    }

    return (
      <Fragment>
        <div ref={this.bg} className={`bg ${bgColor}`} />
        <div className={`blackout ${blackoutClassName}`} />
        <span ref={this.text} className={`text ${textClassName}`}>
          {content}
        </span>
      </Fragment>
    )
  }

  renderButtonOuter = () => {
    const {
      as,
      size,
      animation,
      wrapperProps,
      to,
      isHoverable,
      isDisabled,
      target,
      isHighlight,
      label,
    } = this.props
    const { animationEnd } = this.state
    const inner = this.renderButtonInner()
    const blockHoverClassName = isHoverable ? '' : 'no-hover'
    let outerClassName = `button button-circle button-circle--${size} ${blockHoverClassName} `

    if (isDisabled) {
      outerClassName += 'button-circle--disabled '
    }

    if (animation) {
      outerClassName += animationEnd
        ? ''
        : `button-circle--animating button-circle--animating--${animation} `
    }

    if (isHighlight) {
      outerClassName += 'is-hover '
    }

    const resultWrapperProps = {
      ...wrapperProps,
      className: outerClassName,
    }

    switch (as) {
      case 'button':
        return (
          <button ref={this.wrapper} {...resultWrapperProps} aria-label={label}>
            {inner}
          </button>
        )
      case 'Link':
        return (
          <Link
            innerRef={node => {
              this.wrapper = { current: node }
            }}
            to={to}
            {...resultWrapperProps}
          >
            {inner}
          </Link>
        )
      default:
        return (
          <a
            href={to}
            target={target}
            ref={this.wrapper}
            {...resultWrapperProps}
          >
            {inner}
          </a>
        )
    }
  }

  render() {
    return this.renderButtonOuter()
  }
}

ButtonCircle.propTypes = {
  /** False or type of appearance animation */
  animation: PropTypes.bool,

  /** Customize delay for appearance animation */
  animationDelay: PropTypes.number,

  /** Kind of wrapper component */
  as: PropTypes.oneOf(['a', 'button', 'Link']),

  /** ClassName with color for background tag */
  bgClassName: PropTypes.string,

  /** ClassName with color for blackout tag */
  blackoutClassName: PropTypes.string,

  /** Title of button */
  children: PropTypes.node,

  /** Id of icon — [look Icon component](#!/Icon) */
  icon: PropTypes.string,

  /** Color of Icon. When hover it will become white */
  iconColor: PropTypes.string,

  /** Stroke of Icon */
  iconStrokeWidth: PropTypes.string,

  /** Flag to control disable state */
  isDisabled: PropTypes.bool,

  /** Flag to control possibility of hover action */
  isHoverable: PropTypes.bool,

  /** Size of component */
  size: PropTypes.oneOf(['small', 'base', 'large']),

  /** Target of link tag */
  target: PropTypes.string,

  /** ClassName for text tag */
  textClassName: PropTypes.string,

  /** Use this prop for 'Link' and 'a'  */
  to: PropTypes.string,

  /** Label for buttons aria-label  */
  label: PropTypes.string,

  /** Extra props for wrapper component */
  wrapperProps: PropTypes.object,

  /** Flag to control highlight button */
  isHighlight: PropTypes.bool,
}

ButtonCircle.defaultProps = {
  children: '',
  as: 'a',
  to: '',
  size: 'base',
  bgClassName: 'bg--transp bg--border-gray',
  blackoutClassName: 'bg--purple',
  textClassName: '',
  label: '',
  animation: false,
  animationDelay: 0,
  wrapperProps: {},
  iconColor: 'white',
  iconStrokeWidth: '1.2',
  isDisabled: false,
  isHoverable: true,
  target: '_blank',
  isHighlight: false,
}

export default ButtonCircle
