import CSSPlugin from 'gsapAlias/CSSPlugin'
import TimelineLite from 'gsapAlias/TimelineLite'

// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin]

const WHOLE = 0.8

const GROWING_1 = (WHOLE / 4) * 3
const FADE_IN_2 = WHOLE / 3
const FADE_IN_OVERLAP = Math.abs(WHOLE - FADE_IN_2 - GROWING_1)

export const showButton = (wrapperNode, bgNode, textNode) => {
  const timeline = new TimelineLite()

  timeline
    .set(bgNode, {
      scaleX: 0,
      scaleY: 0,
    })
    .set(textNode, {
      opacity: 0,
    })
    .to(bgNode, GROWING_1, {
      scaleX: 1,
      scaleY: 1,
    })
    .to(
      textNode,
      FADE_IN_2,
      {
        opacity: 1,
      },
      `-=${FADE_IN_OVERLAP + 0.1}`,
    )

  return timeline
}
