import { em, math } from 'polished'
import styled, { css } from 'styled-components'

import DynamicImage from 'components/DynamicImage'
import { BoxBase, Col } from 'styles/components/layout'
import { H2Like } from 'styles/components/typography'

const animationDistance = em(50)
const translateBase = em(40)
const translateBase2x = em(80)

export const BoxBaseStyled = styled(BoxBase)`
  margin: ${em(20)} 0;
  position: relative;

  ${p => p.theme.mq.upMd} {
    margin: ${em(60)} 0 ${em(60)};
    padding-top: ${em(40)};
    padding-right: ${em(80)};
    overflow: hidden;
  }

  ${p => p.theme.mq.upLg} {
    overflow: visible;
    padding-right: ${em(120)};
  }
`

export const ControlsBox = styled.div`
  text-align: center;

  ${p => p.theme.mq.upMd} {
    display: inline-block;
    margin-left: -${p => math(`${p.theme.indents.baseIndentMd} / 2`)};
    margin-bottom: ${em(20)};
  }

  ${p => p.theme.mq.upLg} {
    display: block;
    margin-left: auto;
    margin-bottom: 0;
  }
`

export const ColStyled = styled(Col)`
  padding-left: 0;
  padding-right: 0;
  margin-left: -${p => math(`${p.theme.indents.baseIndentXs}/2`)};
  margin-right: -${p => math(`${p.theme.indents.baseIndentXs}/2`)};

  ${p => p.theme.mq.upMd} {
    padding-left: 0;
    padding-right: 0;
    margin-left: -${p => math(`${p.theme.indents.baseIndentMd}/2`)};
    margin-right: -${p => math(`${p.theme.indents.baseIndentMd}/2`)};
  }
`

export const HeightPlug = styled.div`
  padding-top: 68.817204%;
  margin-bottom: ${em(20)};
`

export const H2LikeStyled = styled(H2Like)`
  margin-right: ${em(10)};
`

export const Wrapper = styled.div`
  position: relative;
`

export const ImageBox = styled.div`
  background-color: ${p =>
    p.colorTheme === 'dark'
      ? p.theme.colors.blueDark
      : p.theme.colors.blackOp07NonTransp};
  position: relative;
  width: 100%;
  transition: transform ${p => p.theme.animation.timeStandard} ease-in
    ${p => p.theme.animation.timeStandard};
  z-index: 2;
`

export const DynamicImageStyled = styled(DynamicImage)`
  pointer-events: none;
  user-select: none;
  transition: opacity ${p => p.theme.animation.timeStandard} ease-in
    ${p => p.theme.animation.timeStandard};
`

export const SlideBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: ${p =>
    p.colorTheme === 'dark'
      ? p.theme.colors.blueDark
      : p.theme.colors.blackOp07NonTransp};
  transform: translate(${translateBase}, -${translateBase});
`

export const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;

  ${({ state, isNextSlide }) =>
    state === 'exiting' &&
    css`
      position: absolute;
      top: 0;

      ${isNextSlide
        ? css`
            ${ImageBox} {
              transform: translate(${translateBase}, -${translateBase});
            }

            ${DynamicImageStyled} {
              opacity: 0;
            }

            ${SlideBg} {
              transition: transform ${p => p.theme.animation.timeStandard},
                opacity ${p => p.theme.animation.timeStandard};
              transform: translate(${translateBase2x}, -${translateBase2x});
              opacity: 0;
            }
          `
        : css`
            ${ImageBox} {
              transform: translate(-100%, 0);
              transition-delay: 0ms;
              transition-timing-function: ease-in;
              transition-duration: ${p => p.theme.animation.timeLong};
              background: transparent;
            }

            ${DynamicImageStyled} {
              transition-delay: 0ms;
              transition-timing-function: ease-in;
              transition-duration: ${p => p.theme.animation.timeLong};
              opacity: 0;
            }
          `}
    `}

  ${({ state, isNextSlide }) =>
    state === 'entering' &&
    css`
      ${isNextSlide
        ? css`
            ${ImageBox} {
              transform: translate(-100%, 0);
              background: transparent;
            }

            ${DynamicImageStyled},
            ${SlideBg} {
              opacity: 0;
            }
          `
        : css`
            ${ImageBox} {
              transform: translate(${translateBase}, -${translateBase});
              background: ${p => p.theme.colors.blackOp07NonTransp};
            }

            ${DynamicImageStyled}, ${SlideBg} {
              opacity: 0;
            }

            ${SlideBg} {
              transform: translate(${translateBase2x}, -${translateBase2x});
            }
          `}
    `}

   ${({ state, isNextSlide }) =>
     state === 'entered' &&
     css`
       ${isNextSlide
         ? css`
             ${ImageBox} {
               background: transparent;
               transform: translate(0, 0);
               opacity: 1;
             }
           `
         : css`
             ${ImageBox} {
               background: ${p => p.theme.colors.blackOp07NonTransp};
               transform: translate(0, 0);
               opacity: 1;
             }

             ${SlideBg} {
               transition-property: transform, opacity;
               transition-duration: ${p => p.theme.animation.timeStandard};
               transition-delay: ${p => p.theme.animation.timeLong};
               opacity: 1;
               transform: translate(${translateBase}, -${translateBase});
             }
           `}
     `}
`

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
`

export const TextBox = styled.div`
  transition: transform ${p => p.theme.animation.timeLong},
    opacity ${p => p.theme.animation.timeLong};
`

export const IconBox = styled.div`
  transition: transform ${p => p.theme.animation.timeStandard};
`

export const ColRelative = styled(Col)`
  position: relative;
`

export const StoryBox = styled.div`
  ${({ state }) =>
    state === 'exiting' &&
    css`
      position: absolute;
      top: 0;

      ${TextBox} {
        transform: translateY(${animationDistance});
        opacity: 0;
      }

      ${IconBox} {
        transform: scale(0);
      }
    `}

  ${({ state }) =>
    state === 'entering' &&
    css`
      ${TextBox} {
        transform: translateY(${animationDistance});
        opacity: 0;
      }

      ${IconBox} {
        transform: scale(0);
      }
    `}

  ${({ state }) =>
    state === 'entered' &&
    css`
      ${TextBox} {
        transform: translateY(0);
        opacity: 1;
      }

      ${IconBox} {
        transition-delay: ${p => p.theme.animation.timeStandard};
        transform: scale(1);
      }
    `}

  ${({ colorTheme }) =>
    colorTheme === 'dark' &&
    css`
      ${TextBox} {
        color: ${p => p.theme.colors.white};
      }
    `}
`
