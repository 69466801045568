import { throttle } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Swipeable } from 'react-swipeable'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import ButtonCircle from 'components/ButtonCircle'
import ControlSliderArrows from 'components/ControlSliderArrows'
import Icon from 'components/Icon'
import { Col, Row } from 'styles/components/layout'
import { Text } from 'styles/components/typography'
import { RU } from 'constants/countries'

import {
  Wrapper,
  ControlsBox,
  SlideBg,
  HeightPlug,
  ImageWrapper,
  ImageBox,
  DynamicImageStyled,
  HeaderBox,
  TextBox,
  IconBox,
  StoryBox,
  H2LikeStyled,
  BoxBaseStyled,
  ColStyled,
  ColRelative,
} from './styles'

const TIME = 10000 // 10 seconds animation duration
const TIMEOUT = 600

const StoriesSliderDesktop = ({ slides, storiesPath, colorTheme, locale }) => {
  const [activeSlide, setActiveSlide] = React.useState(1)
  const [direction, setDirection] = React.useState(1)
  const [isLoopEnabled, setIsLoopEnabled] = React.useState(false)
  const [inView, setInView] = React.useState(false)
  const isAnimationActive = React.useRef(false)
  const currSlide = slides[activeSlide - 1]

  const sliderRef = React.useRef(null)
  const timeout = React.useRef(null)

  const endLoop = () => {
    setIsLoopEnabled(false)
    clearTimeout(timeout.current)
    timeout.current = null
  }

  const onClickRight = React.useCallback(() => {
    if (!isAnimationActive.current && sliderRef.current) {
      setActiveSlide(prevActiveSlide => {
        if (prevActiveSlide === slides.length) {
          return 1
        } else {
          return prevActiveSlide + 1
        }
      })

      isAnimationActive.current = true
      setDirection(1)

      endLoop()
    }
  }, [slides.length])

  const startLoop = React.useCallback(() => {
    setIsLoopEnabled(true)
    timeout.current = setTimeout(onClickRight, TIME)
  }, [onClickRight])

  const onClickLeft = React.useCallback(() => {
    if (!isAnimationActive.current) {
      setActiveSlide(prevActiveSlide => {
        if (prevActiveSlide === 1) {
          return slides.length
        } else {
          return prevActiveSlide - 1
        }
      })

      isAnimationActive.current = true
      setDirection(-1)

      endLoop()
    }
  }, [slides.length])

  const handleAnimationDone = React.useCallback(() => {
    if (isAnimationActive.current) {
      isAnimationActive.current = false
      startLoop()
    }
  }, [startLoop])

  const handleScroll = React.useCallback(() => {
    const { current: sliderNode } = sliderRef
    if (sliderNode) {
      const { top: sliderTop } = sliderNode.getBoundingClientRect()
      const wHeight = window.innerHeight

      if (!inView && (sliderTop < 0 || sliderTop < wHeight)) {
        setInView(true)
      }

      if (sliderTop < wHeight && sliderTop > 0) {
        if (!timeout.current) {
          startLoop()
        }
      } else if (timeout.current) {
        endLoop()
      }
    }
  }, [inView, startLoop])

  const throttledScrollHandle = React.useCallback(throttle(handleScroll, 200), [
    handleScroll,
    inView,
  ])

  React.useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', throttledScrollHandle)
    window.addEventListener('resize', throttledScrollHandle)

    return () => {
      endLoop()
      window.removeEventListener('scroll', throttledScrollHandle)
      window.removeEventListener('resize', throttledScrollHandle)
    }
  }, [handleScroll, throttledScrollHandle, slides])

  const buttonClassName = React.useMemo(
    () => (colorTheme === 'dark' ? 'bg--white--op-07' : 'bg--gray--op-06'),
    [colorTheme],
  )

  return (
    <BoxBaseStyled ref={sliderRef}>
      <Row>
        <Col
          width={{ md: 1, lg: 5 / 12 }}
          maxWidth={{ lg: '100%' }}
          flexBasis={{ lg: 0 }}
          flexGrow={{ lg: 1 }}
        >
          <Wrapper>
            <HeightPlug />
            <TransitionGroup component={null}>
              <CSSTransition
                timeout={TIMEOUT}
                key={activeSlide}
                onExited={handleAnimationDone}
              >
                {state => (
                  <Swipeable
                    onSwipedLeft={onClickLeft}
                    onSwipedRight={onClickRight}
                  >
                    <ImageWrapper {...{ state, isNextSlide: direction > 0 }}>
                      <ImageBox {...{ colorTheme }}>
                        {inView && (
                          <DynamicImageStyled
                            alt={currSlide.person_name}
                            fitOption="contain"
                            image={`${storiesPath}/${currSlide.code}`}
                            imageSizes={{
                              md: 560,
                            }}
                            namesByBreakpoints
                          />
                        )}
                      </ImageBox>
                      <SlideBg {...{ colorTheme }} />
                    </ImageWrapper>
                  </Swipeable>
                )}
              </CSSTransition>
            </TransitionGroup>
          </Wrapper>

          <ControlsBox>
            <ControlSliderArrows
              isLightColor={colorTheme === 'dark'}
              isWide={false}
              amount={slides.length}
              activeIndex={activeSlide}
              isInfinity={true}
              handleLeft={onClickLeft}
              handleRight={onClickRight}
              isWithLoader={true}
              loaderDuration={TIME}
              isLoaderActive={isLoopEnabled}
            />
          </ControlsBox>
        </Col>
        <ColStyled width={{ lg: 1 / 12 }} />
        <ColRelative
          width={{ md: 1, lg: 5 / 12 }}
          maxWidth={{ lg: '100%' }}
          flexBasis={{ lg: 0 }}
          flexGrow={{ lg: 1 }}
        >
          <TransitionGroup component={null}>
            <CSSTransition timeout={600} key={activeSlide}>
              {state => (
                <StoryBox {...{ state, colorTheme }}>
                  <HeaderBox>
                    <TextBox>
                      <H2LikeStyled
                        as="h3"
                        dangerouslySetInnerHTML={{
                          __html: currSlide.person_name,
                        }}
                      />
                    </TextBox>
                    {(locale !== RU ||
                      (currSlide.icon !== 'facebook' &&
                        currSlide.icon !== 'instagram')) && (
                      <IconBox>
                        <ButtonCircle
                          to={currSlide.url}
                          bgClassName={buttonClassName}
                          blackoutClassName="bg--transp"
                        >
                          <Icon id={`btn_${currSlide.icon}`} color="white" />
                        </ButtonCircle>
                      </IconBox>
                    )}
                  </HeaderBox>
                  <TextBox>
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: currSlide.person_profession,
                      }}
                    />
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: currSlide.story_text,
                      }}
                    />
                  </TextBox>
                </StoryBox>
              )}
            </CSSTransition>
          </TransitionGroup>
        </ColRelative>
      </Row>
    </BoxBaseStyled>
  )
}

StoriesSliderDesktop.propTypes = {
  slides: PropTypes.array.isRequired,
  storiesPath: PropTypes.string.isRequired,
  colorTheme: PropTypes.string,
  locale: PropTypes.string,
}

export default StoriesSliderDesktop
